"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var marked = require("marked");
var MarkdownToHtmlPipe = (function () {
    function MarkdownToHtmlPipe() {
    }
    MarkdownToHtmlPipe.prototype.transform = function (markdown, options) {
        if (markdown == null)
            return '';
        return marked(markdown, options);
    };
    MarkdownToHtmlPipe.setOptions = function (options) {
        marked.setOptions(options);
    };
    return MarkdownToHtmlPipe;
}());
MarkdownToHtmlPipe.decorators = [
    { type: core_1.Pipe, args: [{
                name: 'MarkdownToHtml'
            },] },
];
/** @nocollapse */
MarkdownToHtmlPipe.ctorParameters = function () { return []; };
exports.MarkdownToHtmlPipe = MarkdownToHtmlPipe;
