"use strict";
function __export(m) {
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
__export(require("./src/markdown-to-html.pipe"));
var core_1 = require("@angular/core");
var markdown_to_html_pipe_1 = require("./src/markdown-to-html.pipe");
var MarkdownToHtmlModule = (function () {
    function MarkdownToHtmlModule() {
    }
    return MarkdownToHtmlModule;
}());
MarkdownToHtmlModule.decorators = [
    { type: core_1.NgModule, args: [{
                declarations: [markdown_to_html_pipe_1.MarkdownToHtmlPipe],
                exports: [markdown_to_html_pipe_1.MarkdownToHtmlPipe]
            },] },
];
/** @nocollapse */
MarkdownToHtmlModule.ctorParameters = function () { return []; };
exports.MarkdownToHtmlModule = MarkdownToHtmlModule;
